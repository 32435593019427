/* LandingPage.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Additional styles for animation */
.animated {
  animation-duration: 1s;
}

.bounce {
  animation-name: bounce;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-20px);
  }

  60% {
    transform: translateY(-10px);
  }
}
#word {
    text-shadow: 5px 2px #222324, 2px 4px #222324, 3px 5px #222324;
  }